import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { MemberAreaState, MemberAreaStore } from './member-area.store';

@Injectable({ providedIn: 'root' })
export class MemberAreaQuery extends QueryEntity<MemberAreaState> {
  constructor(protected store: MemberAreaStore) {
    super(store);
  }
}
